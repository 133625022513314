var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"digital"}},[_c('slide-transition'),_c('hero-header',{style:({
    background: `linear-gradient(to bottom, rgba(102, 251, 233, 0.47), rgba(102, 251, 233, 0.47)),` + 'url(' + require('./images/digital/cafe.jpg') + ')',
    backgroundPosition: 'center 80% !important',
    backgroundSize: 'cover !important',
    backgroundRepeat: 'no-repeat'
  }),attrs:{"title":"Digital","image":"./images/digital/cafe.jpg"}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"column"},_vm._l((_vm.filteredItems(1, 3)),function(img){return _c('div',{key:img.id,staticClass:"container",on:{"click":function($event){return _vm.openLightboxOnSlide(img.id)}}},[_c('img',{attrs:{"src":require(`./images/digital/${img.image}`),"alt":""}}),_c('div',{staticClass:"overlay"},[_c('div',{staticClass:"text1"},[_c('h2',{staticClass:"mb-0"},[_vm._v(_vm._s(img.name))]),_c('h3',[_vm._v(_vm._s(img.date))])])])])}),0),_c('div',{staticClass:"column"},_vm._l((_vm.filteredItems(2, 3)),function(img){return _c('div',{key:img.id,staticClass:"container",on:{"click":function($event){return _vm.openLightboxOnSlide(img.id)}}},[_c('img',{attrs:{"src":require(`./images/digital/${img.image}`),"alt":""}}),_c('div',{staticClass:"overlay"},[_c('div',{staticClass:"text1"},[_c('h2',{staticClass:"mb-0"},[_vm._v(_vm._s(img.name))]),_c('h3',[_vm._v(_vm._s(img.date))])])])])}),0),_c('div',{staticClass:"column"},_vm._l((_vm.filteredItems(3, 3)),function(img){return _c('div',{key:img.id,staticClass:"container",on:{"click":function($event){return _vm.openLightboxOnSlide(img.id)}}},[_c('img',{attrs:{"src":require(`./images/digital/${img.image}`),"alt":""}}),_c('div',{staticClass:"overlay"},[_c('div',{staticClass:"text1"},[_c('h2',{staticClass:"mb-0"},[_vm._v(_vm._s(img.name))]),_c('h3',[_vm._v(_vm._s(img.date))])])])])}),0)]),_c('div',{staticClass:"photos"},[_c('FsLightbox',{attrs:{"toggler":_vm.toggler,"slide":_vm.slide,"sources":[
      require('./images/digital/campfire.jpg'),
      require('./images/digital/bioshock.jpg'),
      require('./images/digital/cafe.jpg'),
      require('./images/digital/jinx.jpg'),
      require('./images/digital/mel.jpg'),
      require('./images/digital/frogs.jpg'),
      require('./images/digital/connor.jpg'),
      require('./images/digital/spheal.jpg'),
      require('./images/digital/swirlix.jpg'),
      require('./images/digital/piplup.jpg'),
      require('./images/digital/ditto.jpg'),
      require('./images/digital/profile-red.jpg'),
      require('./images/digital/creativity.jpg'),
      require('./images/digital/elf.jpg'),
      require('./images/digital/laracroft.jpg'),
      require('./images/digital/adventure.jpg'),
      require('./images/digital/atla.jpg'),
      require('./images/digital/antman.jpg'),
      require('./images/digital/omniman.jpg'),
      require('./images/digital/person-blue.jpg'),
      require('./images/digital/profile.jpg'),
    ]}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }