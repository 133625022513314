var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"water"}},[_c('slide-transition'),_c('hero-header',{style:({
    background: `linear-gradient(to bottom, rgba(102, 251, 233, 0.47), rgba(102, 251, 233, 0.47)),` + 'url(' + require('./images/watercolour/turtle.jpg') + ')',
    backgroundPosition: 'center center',
    backgroundSize: 'cover !important'
  }),attrs:{"title":"Watercolour","image":"./images/header.jpg"}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"column"},_vm._l((_vm.filteredItems(1, 3)),function(img){return _c('div',{key:img.id,staticClass:"container",on:{"click":function($event){return _vm.openLightboxOnSlide(img.id)}}},[_c('img',{attrs:{"src":require(`./images/watercolour/${img.image}`),"alt":""}}),_c('div',{staticClass:"overlay"},[_c('div',{staticClass:"text1"},[_c('h2',{staticClass:"mb-0"},[_vm._v(_vm._s(img.name))]),_c('h3',[_vm._v(_vm._s(img.date))])])])])}),0),_c('div',{staticClass:"column"},_vm._l((_vm.filteredItems(2, 3)),function(img){return _c('div',{key:img.id,staticClass:"container",on:{"click":function($event){return _vm.openLightboxOnSlide(img.id)}}},[_c('img',{attrs:{"src":require(`./images/watercolour/${img.image}`),"alt":""}}),_c('div',{staticClass:"overlay"},[_c('div',{staticClass:"text1"},[_c('h2',{staticClass:"mb-0"},[_vm._v(_vm._s(img.name))]),_c('h3',[_vm._v(_vm._s(img.date))])])])])}),0),_c('div',{staticClass:"column"},_vm._l((_vm.filteredItems(3, 3)),function(img){return _c('div',{key:img.id,staticClass:"container",on:{"click":function($event){return _vm.openLightboxOnSlide(img.id)}}},[_c('img',{attrs:{"src":require(`./images/watercolour/${img.image}`),"alt":""}}),_c('div',{staticClass:"overlay"},[_c('div',{staticClass:"text1"},[_c('h2',{staticClass:"mb-0"},[_vm._v(_vm._s(img.name))]),_c('h3',[_vm._v(_vm._s(img.date))])])])])}),0)]),_c('div',{staticClass:"photos"},[_c('FsLightbox',{attrs:{"toggler":_vm.toggler,"slide":_vm.slide,"sources":[
      require('./images/watercolour/spider.jpg'),
      require('./images/watercolour/gwen.jpg'),
      require('./images/watercolour/doggo.jpg'),
      require('./images/watercolour/turtle.jpg'),
      require('./images/watercolour/ironman.jpg'),
      require('./images/watercolour/jv.jpg'),
      require('./images/watercolour/yoda.jpg'),
      require('./images/watercolour/hyuna.jpg'),
    ]}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }