var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"acrylic"}},[_c('slide-transition'),_c('hero-header',{style:({
    background: `linear-gradient(to bottom, rgba(102, 251, 233, 0.47), rgba(102, 251, 233, 0.47)),` + 'url(' + require('./images/acrylic/car.jpg') + ')',
    backgroundPosition: 'center center',
    backgroundSize: 'cover'
  }),attrs:{"title":"Acrylic","image":"./images/header.jpg"}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"column"},[_c('div',{staticClass:"container",on:{"click":function($event){return _vm.openLightboxOnSlide(1)}}},[_c('img',{attrs:{"src":require("./images/acrylic/eye.jpg"),"alt":""}}),_vm._m(0)]),_c('img',{attrs:{"src":require("./images/cyan.png"),"aria-hidden":"true","alt":""}}),_c('div',{staticClass:"container",on:{"click":function($event){return _vm.openLightboxOnSlide(2)}}},[_c('img',{attrs:{"src":require("./images/acrylic/butterfly.jpg"),"alt":""}}),_vm._m(1)])]),_c('div',{staticClass:"column"},[_c('img',{attrs:{"src":require("./images/dark-grey.png"),"aria-hidden":"true","alt":""}}),_c('div',{staticClass:"container",on:{"click":function($event){return _vm.openLightboxOnSlide(3)}}},[_c('img',{attrs:{"src":require("./images/acrylic/Rosa.jpg"),"alt":""}}),_vm._m(2)]),_c('img',{attrs:{"src":require("./images/black.png"),"aria-hidden":"true","alt":""}})]),_c('div',{staticClass:"column"},[_c('div',{staticClass:"container",on:{"click":function($event){return _vm.openLightboxOnSlide(4)}}},[_c('img',{attrs:{"src":require("./images/acrylic/tractor.jpg"),"alt":""}}),_vm._m(3)]),_c('img',{attrs:{"src":require("./images/light-grey.png"),"aria-hidden":"true","alt":""}}),_c('div',{staticClass:"container",on:{"click":function($event){return _vm.openLightboxOnSlide(5)}}},[_c('img',{attrs:{"src":require("./images/acrylic/mouth.jpg"),"alt":""}}),_vm._m(4)])])]),_c('div',{staticClass:"photos"},[_c('FsLightbox',{attrs:{"toggler":_vm.toggler,"slide":_vm.slide,"sources":[
      require('./images/acrylic/eye.jpg'),
      require('./images/acrylic/butterfly.jpg'),
      require('./images/acrylic/Rosa.jpg'),
      require('./images/acrylic/tractor.jpg'),
      require('./images/acrylic/mouth.jpg'),
    ]}})],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"overlay"},[_c('div',{staticClass:"text1"},[_c('h2',{staticClass:"mb-0"},[_vm._v("Eye")]),_c('h3',[_vm._v("2019-05-07")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"overlay"},[_c('div',{staticClass:"text1"},[_c('h2',{staticClass:"mb-0"},[_vm._v("Butterfly")]),_c('h3',[_vm._v("2016-07-22")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"overlay"},[_c('div',{staticClass:"text1"},[_c('h2',{staticClass:"mb-0"},[_vm._v("Rosa Diaz")]),_c('h3',[_vm._v("2019-05-07")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"overlay"},[_c('div',{staticClass:"text1"},[_c('h2',{staticClass:"mb-0"},[_vm._v("Tractor")]),_c('h3',[_vm._v("2018-05-21")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"overlay"},[_c('div',{staticClass:"text1"},[_c('h2',{staticClass:"mb-0"},[_vm._v("Nose and Mouth")]),_c('h3',[_vm._v("2019-05-03")])])])
}]

export { render, staticRenderFns }